//некоторый общий css для форм, используется в админке и на странице редактирования профиля
@use 'vars';

form.form {
    width: 100%;
    overflow: auto;
}

.form {
    .form {
        &__ {
            &add-btn,
            &rm-btn {
                position: absolute;
                top: 0;
                @include vars.themifyModule() {
                    right: -#{vars.marginStep(3)};
                    height: 100%;
                }
            }

            &add-btn {
                @include vars.themifyModule() {
                    color: vars.color('typo-link');
                }
            }

            &rm-btn {
                @include vars.themifyModule() {
                    color: vars.color('control-bg-default');
                }
            }
        }
    }
}

.form-section {
    @include vars.themifyModule() {
        margin-bottom: vars.marginStep(7);
    }

    :global(.ant-pagination.ant-table-pagination) {
        margin-bottom: 0;
    }
}
