@use 'vars';

.panels {
    display: flex;
}

.panel {
    flex-basis: 50%;

    &:not(:last-child) {
        @include vars.themifyModule() {
            margin-right: vars.marginStep(4);
        }
    }
}

.password {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(8);
        color: vars.color('typo-link');
        @include vars.text('font-m-regular');
    }
}

.save {
    @include vars.themifyModule() {
        margin-top: vars.marginStep(5);
    }
}
