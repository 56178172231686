@use 'vars';

.field {
    position: relative;
    &:not(:last-child) {
        @include vars.themifyModule() {
            margin-bottom: vars.marginStep(5);
        }
    }
}
