@use 'vars';

.header {
    @include vars.themifyModule() {
        @include vars.text('font-m-regular');
        margin-bottom: vars.marginStep();
    }
    text-transform: uppercase;
}

.divider {
    height: 1px;
    @include vars.themifyModule() {
        background: vars.color('control-border');
        margin-bottom: vars.marginStep(4);
    }
}

.children {
    width: 55%;
}
